<template>
  <div>
    <TableFilter @searchFilter="searchFilter" @filterPage="filterPage" />
    <TableStatistic
      v-if="Object.keys(stadisticsValue).length > 0"
      :stadisticsValue="stadisticsValue"
    />
    <b-card body-class="p-1">
      <b-row class="mb-1 info-footer">
        <b-col
          class="d-flex align-items-center justify-content-start flex-wrap mr-auto"
          cols="auto"
        >
          <div>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              style="width: 190px"
              id="select-per-page"
            >
              <!-- Opciones -->
              <template #option="{ ...perPage }">
                <div class="d-flex justify-content-between">
                  <span>
                    {{ $t("Show") }} {{ perPage.label }} {{ $t("Entries") }}
                  </span>
                </div>
              </template>
              <!-- seleccionado -->
              <template #selected-option="{ ...perPage }">
                <div class="d-flex justify-content-between">
                  <span>
                    {{ $t("Show") }} {{ perPage.label }} {{ $t("Entries") }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
          <div class="border-left">
            <b-dropdown text="Copy report" variant="flat-primary">
              <b-dropdown-item
                v-for="(types, index) in optionsDocument"
                :key="index"
                @click="downloadDocument(types)"
              >
                {{ types }}
              </b-dropdown-item>
              <!--  <b-dropdown-item> Excel </b-dropdown-item> -->
            </b-dropdown>
          </div>
          <div class="border-left">
            <b-button
              :disabled="!conditionOptionsOnlyUser"
              variant="flat-primary"
            >
              Patient Profile
            </b-button>
          </div>
          <div class="border-left">
            <b-button
              :disabled="!conditionOptionsOnlyUser"
              variant="flat-primary"
            >
              Message
            </b-button>
          </div>
          <div class="border-left">
            <b-button
              :disabled="!conditionOptionsOnlyUser"
              variant="flat-primary"
            >
              Enrollment
            </b-button>
          </div>
          <div class="border-left">
            <b-button @click="updateVisits" variant="flat-primary">
              Update Visits
            </b-button>
          </div>
        </b-col>
        <!-- {{ $t("Search") }} -->
        <b-col cols="auto">
          <GoBack class="mr-2" />
        </b-col>
      </b-row>
      <b-table
        hover
        bordered
        responsive
        striped
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
        :items="items"
        :fields="fields"
        no-border-collapse
        :busy="isBusy"
      >
        <template #head(select)="">
          <b-form-checkbox
            v-model="CheckAllData"
            class="vs-checkbox-con"
            @change="onChangeCheckAll"
          >
          </b-form-checkbox>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <SpinnerLoading />
          </div>
        </template>
        <template #cell(select)="row">
          <b-form-checkbox v-model="row.item.select" class="vs-checkbox-con">
          </b-form-checkbox>
        </template>
        <template #cell(phone)="data">
          <div class="text-nowrap">
            <span
              v-if="data.item.patients && data.item.patients.phone"
              class="align-text-top text-capitalize"
              >{{ formatPhone(data.item.patients.phone) }}</span
            >
          </div>
        </template>
        <template #cell(patients)="data">
          <div class="d-flex">
            <b-link
              :to="{
                name: 'patients-view',
                params: { id: data.item.patients.id },
              }"
              class="text-left"
            >
              <p class="font-weight-bold d-flex m-0 pl-1">
                {{ data.item.patients.name }} {{ data.item.patients.lastname }}
              </p>
              <small class="text-muted d-flex pl-1">{{
                data.item.patients.email
              }}</small>
            </b-link>
          </div>
        </template>
        <template #cell(facilities)="data">
          <b-link
            :to="{
              name: 'admin-facilities-view',
              params: { id: data.item.facilities.id },
            }"
          >
            <span>{{ data.item.facilities.name }}</span>
          </b-link>
        </template>
        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit.omas" vertical-align="center">
            <div class="d-flex justify-content-start align-items-center">
              <b-avatar size="32" :src="data.item.office_visit.omas.avatar" />
              <b-link
                :to="{
                  name: 'web-settings-users',
                  params: { id: data.item.office_visit.omas.id },
                }"
                class="text-left"
              >
                <p class="font-weight-bold d-flex m-0 pl-1">
                  {{ data.item.office_visit.omas.name }}
                  {{ data.item.office_visit.omas.lastname }}
                </p>
              </b-link>
            </div>
          </b-media>
        </template>
        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(insurances_id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              isInsuranceTable(data.item)
            }}</span>
          </div>
        </template>
        <template #cell(copay)="data">
          <div class="text-nowrap" v-b-tooltip.html.hover.top="getMovement(data.item.copay_movement)">
            <span>{{ data.item.copay }}</span>
          </div>
        </template>
        <template #cell(paid)="data">
          <b-link
            class="text-nowrap"
            :to="{
              name: 'recipe-patient',
              params: { recipeId: data.item.id },
            }"
            v-b-tooltip.html.hover.top="getMovement(data.item.all_movement)"
          >
            <span
              class="font-weight-bolder"
              :class="[
                `text-${resolveUserPayStatusVariant(data.item.pay_status_id)}`,
              ]"
              >{{ data.item.paid }}</span
            >
          </b-link>
        </template>
      </b-table>

      <b-card class="info-footer mb-0" body-class="p-0 pt-1">
        <PaginationTable
          :dataMeta="dataMeta"
          :totalUsers="totalUsers"
          :perPage="perPage"
          :currentPage="currentPage"
          @page-changed="pageChanged"
        />
      </b-card>
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BRow,
  BCol,
  BLink,
  BBadge,
  BAvatar,
  BMedia,
  VBTooltip,
} from "bootstrap-vue";
import TableFilter from "@/views/admin/admin-data/admin-data/TableFilter";
import TableStatistic from "@/views/admin/admin-data/admin-data/TableStatistic";

import PaginationTable from "@/components/PaginationTable";
import GoBack from "@/components/ButtonBack.vue";
import vSelect from "vue-select";
import SpinnerLoading from "@/components/SpinnerLoading";
import ComingSoon from "@/views/pages/miscellaneous/ComingSoon";
import ToastNotification from "@/components/ToastNotification";

import { formatPhone } from "@/mixins/formatPhone.js";
import axiosAD from "@/services/admin/admin-data";

export default {
  components: {
    TableFilter,
    TableStatistic,
    PaginationTable,
    SpinnerLoading,
    GoBack,
    vSelect,
    BRow,
    BCol,
    BTable,
    BCard,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BLink,
    BBadge,
    BAvatar,
    BMedia,
    ComingSoon,
    ToastNotification,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    perPage(newVal) {
      if (this.filtro) {
        this.searchFilter(this.dataFilter);
      }
    },
  },
  data() {
    return {
      formatPhone,
      fields: [
        {
          key: "select",
          label: `Select`,
        },
        {
          key: "visit_date",
          label: "Date of service",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "patients",
          label: "Name",
          class: "th-class-adjusted-admindata",
        },
        {
          key: "patients.date_birth",
          label: "DOB",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "phone",
          label: "Phone number",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "facilities",
          label: "Facility",
          class: "th-class-adjusted-admindata",
        },
        { key: "oma", label: "OMA", class: "th-class-adjusted-admindata" },
        {
          key: "type_visits",
          label: "Visit Type",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "insurances_id",
          label: "Encounter Type",
          class: "th-class-adjusted-admindata",
        },
        {
          key: "progresstime",
          label: "Visit Duration",
          class: "th-class-adjusted-admindata",
        },
        {
          key: "copay",
          label: "Co-pay",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "ajustment",
          label: "Adjusment",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "balance",
          label: "Balance",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "paid",
          label: "Pay",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
      ],
      items: [],
      stadisticsValue: [],
      isBusy: false,
      CheckAllData: false,
      dataMeta: { from: 0, to: 0, of: 0 },
      totalUsers: 0,
      perPage: 50,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      dataFilter: null,
      conditionOptionsOnlyUser: true,
      optionsDocument: ["PDF", "Excel"],
    };
  },
  methods: {
    onChangeCheckAll() {
      this.items.forEach((item) => {
        item.select = this.CheckAllData;
      });
      this.CheckAllData
        ? (this.conditionOptionsOnlyUser = false)
        : (this.conditionOptionsOnlyUser = true);
    },
    pageChanged(page) {
      if (this.filtro) {
        axiosAD
          .adminDataFilterPagination(this.perPage, page, this.dataFilter)
          .then(
            ({
              registro: {
                appointments: { data, total, current_page, ...res },
                statistics,
              },
            }) => {
              this.stadisticsValue = statistics;
              const dataReturn = data.map((item) => {
                return {
                  ...item,
                  select: false,
                };
              });
              this.items = dataReturn;
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            }
          );
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.isBusy = true;
        this.dataFilter = value;
        axiosAD
          .adminDataFilter(this.perPage, value)
          .then(
            ({
              registro: {
                appointments: { data, total, current_page, ...res },
                statistics,
              },
            }) => {
              this.isBusy = false;
              this.stadisticsValue = statistics;
              const dataReturn = data.map((item) => {
                return {
                  ...item,
                  select: false,
                };
              });
              this.items = dataReturn;
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            }
          )
          .catch(() => {
            this.isBusy = false;
          });
      } else {
        this.items = [];
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    downloadDocument(event) {
      axiosAD.adminDataExport(this.dataFilter).then((res) => {
        const blob = new Blob([res]);
        const filename = `admin_data_${this.dataFilter.date_start}_to_${this.dataFilter.date_end}.xlsx`;

        const urlExcel = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlExcel;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(urlExcel);
        document.body.removeChild(a);
      });
    },
    isInsuranceTable(item) {
      //poner Insurance si tiene insurances_id o isInsuranceOnly en true
      if (item.insurances_id || item.isInsuranceOnly) {
        return "Insurance";
      } else {
        return "Self pay";
      }
    },
    updateVisits() {
      const visits = this.items
        .filter((data) => data.select == true)
        .map((selectedItem) => selectedItem.id);
      if (visits.length < 1)
        return this.$refs.toast.warning("you must select elements");

      axiosAD
        .adminDataUpdateVisit({ ids: visits })
        .then(({ status, message }) => {
          this.$refs.toast.success(message);
          this.searchFilter(this.dataFilter);
        })
        .catch((error) => {
          this.$refs.toast.danger(error.message);
        });
    },
    resolveUserPayStatusVariant(status) {
      if (status === 1) return "success";
      if (status === 2) return "unpaid";
      if (status === 3) return "unpaid";
      if (status === 4) return "secondary";
      if (status === 5) return "primary";
      return "unpaid";
    },
    getMovement(objectTime) {
      return `cash ${objectTime.cash} <br> cc ${objectTime.cc}`;
    },
  },
};
</script>
<style lang="scss">
.th-class-adjusted-admindata {
  padding: 0.5rem 0.5rem !important;
}
.th-class-adjusted-admindata.names {
  width: 17rem;
}
</style>
