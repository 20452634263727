import { userAxios } from '@/services'

const adminDataFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`adminData/statistics/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const adminDataFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`adminData/statistics/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const adminDataExport = async (data) => {
    try {
        return await userAxios.post(`adminData/statistics/export/excel`, data, {
            responseType: 'blob',
        }).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const adminDataUpdateVisit = async (data) => {
    try {
        return await userAxios.post(`adminData/statistics/action/change_visit`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    adminDataFilter,
    adminDataFilterPagination,
    adminDataExport,
    adminDataUpdateVisit
}