<template>
  <b-card no-body class="mb-1">
    <b-card-header
      :class="!visible ? 'p-1' : 'px-1 pt-1 pb-0'"
      class="cursor-pointer"
      @click="visible = !visible"
      style="transition: all 0.5s ease"
    >
      <div class="d-flex justify-content-between w-100">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body class="pt-0">
        <b-tabs v-model="currentTab">
          <b-tab title="Statistics" active>
            <StatisticsFilter
              :filters="filters[0]"
              :dataOptions="dataOptions"
            />
          </b-tab>
          <b-tab title="Timeline">
            <TimelineFilter :filters="filters[1]" :dataOptions="dataOptions" />
          </b-tab>
          <b-tab title="Procedures">
            <ProceduresFilter
              :filters="filters[2]"
              :dataOptions="dataOptions"
            />
          </b-tab>
        </b-tabs>
        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button
              variant="primary"
              @click="searchFilter"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import StatisticsFilter from "./filters/StatisticsFilter";
import TimelineFilter from "./filters/TimelineFilter";
import ProceduresFilter from "./filters/ProceduresFilter";

import axiosAF from "@/services/admin/facilities";
import axiosU from "@/services/admin/user";
import axiosTV from "@/services/admin/consults/visits";

export default {
  components: {
    BCollapse,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    vSelect,
    SpinnerLoading,

    StatisticsFilter,
    TimelineFilter,
    ProceduresFilter,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getFacilities();
    this.getUsers();
    this.getTypeVisit();
  },
  data() {
    const dateUsa = new Date().toLocaleDateString("en-US");
    const date = dateUsa.split('/').join('-');
    return {
      currentTab: 1,
      visible: false,
      dataOptions: {
        facilityOptions: [],
        omaOptions: [],
        typeVisitOptions: [],
      },
      filters: [
        {
          date_start: `${date} 07:00`,
          date_end: `${date} 23:59`,
          facility: [],
          oma: null,
          type_visit: null,
          insurance: null,
          vpd: null,
          individual_avg: null,
          clinic_avg: null,
        },
        {
          date_start: `${date} 07:00`,
          date_end: `${date} 23:59`,
          facility: null,
          oma: null,
          type_visit: null,
          encounter_type: null,
          timeline: null,
        },
        {
          date_start: `${date} 07:00`,
          date_end: `${date} 23:59`,
          facility: null,
          oma: null,
          type_visit: null,
          encounter_type: null,
          procedure: null,
        },
      ],
    };
  },
  methods: {
    searchFilter() {
      this.visible = false;
      const filter = this.filters[this.currentTab];
      let cont = 0;
      for (const iterator in filter) {
        const element = this.filters[this.currentTab][iterator];
        if (element == "" || element == null) {
          cont++;
        }
      }
      if (filter.length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(filter)));
    },
    resetFilter() {
      const filter = this.filters[this.currentTab];
      for (const key in filter) {
        filter[key] = null;
      }
    },
    getFacilities() {
      axiosAF
        .facilityList(20)
        .then((response) => {
          this.dataOptions.facilityOptions = response.data.map((item) => {
            return {
              ...item,
              disabled: true
            }
          });
          this.dataOptions.facilityOptions.unshift({
            id: null,
            name: 'Select All',
            selectAll: true,
            disabled: true
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsers() {
      const data = {
        role: "OMA",
				status:true
      };
      axiosU
        .userFilter(20, data)
        .then(({ registro: { data } }) => {
          this.dataOptions.omaOptions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTypeVisit() {
      axiosTV
        .visitList(20)
        .then((response) => {
          this.dataOptions.typeVisitOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
