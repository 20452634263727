<template>
  <div>
    <DateRange
      :filters="filters"
      :optionsData="dataOptions"
    >
      <slot>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Timeline Range</label>
          <v-select
            v-model="filters.procedure"
            class="w-100"
            :options="[]"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
      </slot>
    </DateRange>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRange from "./components/FilterSelect.vue";
export default {
  components: {
    vSelect,
    BRow,
    BCol,

    DateRange,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
  },
};
</script>
