<template>
  <div>
    <FilterSelect
      :filters="filters"
      :secondCol="3"
      :thirdCol="3"
      :fourthCol="3"
      :optionsData="dataOptions"
    >
      <slot>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Income</label>
          <v-select
            v-model="filters.income"
            class="w-100"
            :options="incomeOptions"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
      </slot>
    </FilterSelect>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import FilterSelect from "./components/FilterSelect.vue";
export default {
  components: {
    vSelect,
    BRow,
    BCol,

    FilterSelect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      incomeOptions: [
        { value: 1, name: "Total Income" },
        { value: 2, name: "Income Cash" },
        { value: 3, name: "Income Credit/Debit card" },
      ],
    };
  },
};
</script>
