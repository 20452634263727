<template>
  <b-card no-body class="mb-1">
    <b-card-header
      :class="!visible ? 'p-1' : 'px-1 pt-1 pb-0'"
      class="cursor-pointer"
      @click="visible = !visible"
      style="transition: all 0.5s ease"
    >
      <div class="d-flex justify-content-between w-100">
        <h5>STATISTICS</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body class="pt-0">
        <div class="d-flex justify-content-start" style="overflow: auto">
          <!-- headers -->
          <b-card
            class="border-right sticky-card"
            style="flex-shrink: 0; width: 14rem"
            bg-variant="primary"
          >
            <b-list-group class="list-group list-group-flush text-white">
              <b-list-group-item
                v-for="(header, index) of headers"
                :key="index"
                class="list-group-item px-0 disabled bg-primary text-white"
              >
                <h6 class="align-self-center text-white my-auto" :style="'height:'+header.height">
                  {{ header.name }}
                </h6>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <!-- data -->
          <b-card
            v-for="(clinic, indexData) of dataStats"
            :key="indexData"
            body-class=""
            style="flex-shrink: 0"
            class="rounded border-right"
          >
            <b-list-group class="list-group list-group-flush">
              <!-- Object.keys(clinic) -->
              <b-list-group-item
                v-for="(info, indexStats) in headers"
                :key="indexStats"
                class="list-group-item list-group-item-action px-0 disabled"
              >
                <template v-if="typeof (detail =getDetail(clinic, indexStats)) === 'object'">
                  <template v-if="Array.isArray(detail)">
                    <h6
                      v-for="item in detail"
                      :key="item.key"
                      :style="{color: headers[indexStats].color}"
                    >
                      {{ item.key }}: {{ `${headers[indexStats].money ? '$' : ''} ${item.value}` }}
                    </h6>
                  </template>
                  <h6 v-else :style="{color: headers[indexStats].color}">
                    {{ `${headers[indexStats].money ? '$' : ''} ${detail}` }}
                  </h6>
                </template>
                <!-- default -->
                <h6 v-else :style="{color: headers[indexStats].color}">
                  {{ `${headers[indexStats].money ? '$' : ''} ${detail}` }}
                </h6>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BTable,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
export default {
  components: {
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BTable,
    BListGroup,
    BListGroupItem,
    StatisticCardHorizontal,
  },
  props: {
    stadisticsValue: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      visible: true,
      fields: ["stats"],
      items: [],
      headers: [
        {
          name: "Clinic",
          height: "1.6rem",
          field: 'facility',
          keys: [],
          color: '#000'
        },
        {
          name: "Patients",
          height: "8.6rem",
          field: 'patients',
          keys: [
            {key: 'total', value: 'Total'},
            {key: 'priority', value: 'Priority'},
            {key: 'np', value: 'NP'},
            {key: 'es', value: 'ES'},
            {key: 'value', value: 'Value'},
          ],
          color: '#000'
        },
        {
          name: "Patients AVG",
          height: "8.5rem",
          field: 'patients_avg',
          keys: [
            {key: 'vpd', value: 'VPD'},
            {key: 'priority_avg', value: 'Priority AVG'},
            {key: 'np_avg.total', value: 'NP'},
            {key: 'es_avg.total', value: 'ES'},
            {key: 'value_avg.total', value: 'Value AVG'},
          ],
          color: '#1fcacc'
        },
        {
          name: "Billing Avg",
          height: "8.5rem",
          field: 'billing_avg',
          keys: [
            {key: 'ticket_avg.total', value: 'Ticket'},
            {key: 'priority_avg.total', value: 'Priority'},
            {key: 'np_avg.total', value: 'NP'},
            {key: 'es_avg.total', value: 'ES'},
            {key: 'value_avg.total', value: 'Value'},
          ],
          color: '#249934',
          money: true
        },
        {
          name: "Cash balance",
          height: "1.6rem",
          field: 'cash_balance.cash.total',
          keys: [],
          color: '#000',
          money: true
        },
        {
          name: "CC balance",
          height: "1.6rem",
          field: 'cash_balance.cc.total',
          keys: [],
          color: '#000',
          money: true
        },
        {
          name: "Copay",
          height: "1.6rem",
          field: 'cash_balance.copay.total',
          keys: [],
          color: '#000',
          money: true
        },
        {
          name: "Gross sales",
          height: "5.2rem",
          field: 'gross_sales',
          keys: [
            {key: 'total.total', value: 'Total'},
            {key: 'priority_sales.total', value: 'Priority sales'},
            {key: 'value_sales.total', value: 'Value sales'},
          ],
          color: '#249934',
          money: true
        },
        {
          name: "Expenses",
          height: "5.2rem",
          field: 'expense',
          keys: [
            {key: 'total.total', value: 'Total'},
            {key: 'bonos.total', value: 'Bonos'},
            {key: 'devolutions.total', value: 'Devolutions'},
          ],
          color: '#d22310',
          money: true
        },
        {
          name: "Visit Type",
          height: "1.6rem",
          field: 'sum_visit_type',
          keys: [],
          dinamic: true,
          color: '#000'
        },
      ],
      dataStats: [...this.stadisticsValue].sort((a, b) =>
        a.facility.localeCompare(b.facility)
      ),
    };
  },
  watch: {
    stadisticsValue() {
      const sortedArray = [...this.stadisticsValue].sort((a, b) =>
        a.facility.localeCompare(b.facility)
      );
      this.dataStats = sortedArray;
    },
  },
  methods: {
    currencyPrefix(indexOrBoolean) {
      if (typeof indexOrBoolean === "boolean") {
        return indexOrBoolean ? "$" : "";
      }

      if (
        indexOrBoolean === 5 ||
        indexOrBoolean === 6 ||
        indexOrBoolean === 7
      ) {
        return "$";
      }

      return "";
    },
    getDetail(data, index){
      const keysArray = this.headers[index].field.split('.');
      let value = data;
      const values = [];
      for (const key of keysArray) {
        if(!value && !value.hasOwnProperty(key)) return undefined;
        value = value[key];

        for (const item of this.headers[index].keys) {
          const keysItem = item.key.split('.');
          values.push({
            key: item.value,
            value: keysItem.length > 1 ? value[keysItem[0]][keysItem[1]] : value[item.key]
          });
        }

        // iterar objeto dinamico
        if(this.headers[index].dinamic){
          if(!Array.isArray(value)){
            const objeto = value;
            const ordenDeseado = ['NP', 'ES', 'FW', 'RS', 'AP', 'PI', 'PS', 'CT', 'FV', 'AW'];
            const objetoOrdenado = {};

            ordenDeseado.forEach((keyObj) => {
              if(objeto.hasOwnProperty(keyObj)){
                objetoOrdenado[keyObj] = objeto[keyObj];
              }
            });

            for (const keyObject in objetoOrdenado) {
              values.push({
                key: keyObject,
                value: objetoOrdenado[keyObject]
              });
            }
          }
        }
      }
      return values.length > 0 ? values : value;
    }
  },
};
</script>

<style lang="scss" scoped>
.sticky-card {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $primary #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
