<template>
  <div>
    <DateRange :filters="filters" :optionsData="dataOptions">
      <slot>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Timeline Range</label>
          <!-- <v-select
            v-model="filters.timeline"
            class="w-100"
            :options="facilityOptions"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          /> -->
          <flat-pickr
            v-model="filters.timeline"
            :config="config"
            class="form-control"
            placeholder="MM-DD-YYYY"
          />
        </b-col>
      </slot>
    </DateRange>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import DateRange from "./components/FilterSelect.vue";
import { fromConfig } from "@/mixins/configFormatCal";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,

    DateRange,
  },
  props:{
    filters: {
      type: Object,
      required: true,
    },
    dataOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      config: fromConfig({mode: "range", noCalendar: true, dateFormat: "H:i", defaultHour: false}),
    };
  },
};
</script>
