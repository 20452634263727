<template>
  <b-row>
    <b-col cols="12" md="3" class="d-flex flex-column">
      <label>Date Range</label>
      <div class="d-flex align-items-center flex-wrap">
        <flat-pickr
          v-model="filters.date_start"
          :config="fConfig"
          class="form-control"
          placeholder="MM-DD-YYYY H:i"
        />
        <flat-pickr
          v-model="filters.date_end"
          :config="uConfig"
          class="form-control ml-xl-1"
          placeholder="MM-DD-YYYY H:i"
        />
      </div>
    </b-col>
    <b-col cols="12" :md="secondCol" class="mb-md-0 mb-2">
      <label>Facility</label>
      <v-select
        ref="select-facility"
        v-model="filters.facility"
        class="w-100"
        :options="optionsData.facilityOptions"
        @input="handleFacilitySelection"
        :selectable="(value) => value.disabled"
        :reduce="(value) => value.id"
        label="name"
        multiple
        @keyup.enter="searchFilter"
      >
      </v-select>
    </b-col>
    <b-col cols="12" :md="thirdCol" class="mb-md-0 mb-2">
      <label>OMA</label>
      <v-select
        v-model="filters.oma"
        class="w-100"
        :options="optionsData.omaOptions"
        :reduce="(value) => value.id"
        label="name"
        @keyup.enter="searchFilter"
        @search="searchOma"
      >
        <template slot="option" slot-scope="option">
          <div class="d-flex">
            <b-avatar size="26" variant="light" :src="option.avatar" />
            <span class="ml-1"> {{ option.name }} {{ option.lastname }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            <b-avatar size="26" variant="light" :src="option.avatar" />
            <span class="ml-1"> {{ option.name }} {{ option.lastname }}</span>
          </div>
        </template>
      </v-select>
    </b-col>
    <b-col cols="12" :md="fourthCol" class="mb-md-0 mb-2">
      <label>Encounter Type</label>
      <v-select
        ref="select-encounter"
        v-model="filters.insurance"
        class="w-100"
        :options="encounterOptions"
        @input="handleEncounterSelection"
        :selectable="(value) => value.disabled"
        :reduce="(value) => value.value"
        label="name"
        @keyup.enter="searchFilter"
      />
    </b-col>
    <b-col cols="12" md="3" class="mb-md-0 mb-2">
      <label>Visit Type</label>
      <v-select
        v-model="filters.type_visit"
        class="w-100"
        :options="optionsData.typeVisitOptions"
        :reduce="(value) => value.id"
        label="name"
        @keyup.enter="searchFilter"
      />
    </b-col>
    <slot></slot>
  </b-row>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

import axiosU from "@/services/admin/user";

export default {
  components: {
    flatPickr,
    vSelect,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    secondCol: {
      type: Number,
      default: 3,
    },
    thirdCol: {
      type: Number,
      default: 3,
    },
    fourthCol: {
      type: Number,
      default: 3,
    },
    filters: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      encounterOptions: [
        { value: null, name: "Select All", selectAll: true, disabled: true },
        { value: true, name: "Insurance", disabled: true },
        { value: false, name: "Self Pay", disabled: true },
      ],
      fConfig: fromConfig({isStatic: true, defaultHour: false}),
      uConfig: untilConfig({isStatic: true, defaultHour: false}),
      clearTimeoutBuscador: "",
      isSelectedAll: false,
      isMounted: false,
    };
  },
  watch: {
    "filters.facility": {
      handler() {
        if (this.filters.facility) {
          const options = this.filters.facility;
          const objetosSeleccionados = this.optionsData.facilityOptions.filter(
            (objeto) => options.includes(objeto.id)
          );
          const findItem = objetosSeleccionados.find((item) => item.selectAll);
          if (findItem) {
            this.filters.facility = null;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    searchOma(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["OMA"],
          status: true,
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            this.optionsData.omaOptions = data;
            loading(false);
          });
        }, 400);
      }
    },
    handleFacilitySelection() {
      const options = this.filters.facility;
      const objetosSeleccionados = this.optionsData.facilityOptions.filter(
        (objeto) => options.includes(objeto.id)
      );
      const findItem = objetosSeleccionados.find((item) => item.selectAll);
      const select = this.$refs["select-facility"];
      if (findItem) {
        this.filters.facility = [findItem.id];
        select.options.forEach((item) => (item.disabled = false));
      } else {
        select.options.forEach((item) => (item.disabled = true));
      }
      select.open = false;
    },
    handleEncounterSelection() {
      const options = this.filters.insurance;
      const select = this.$refs["select-encounter"];
      // if (options === 1) {
      //   select.options.forEach((item) => (item.disabled = false));
      // } else {
      //   select.options.forEach((item) => (item.disabled = true));
      // }
      // select.open = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.flatpickr-wrapper {
  width: 50% !important;
}
</style>
